import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {CreateChairDialogComponent} from "./create-chair-dialog/create-chair-dialog.component";
import {Chair} from "../../../models/chair.model";
import {ArticlesService} from "../../../services/articles.service";
import {ChairProperty} from "../../../models/chair-property.enum";
import {ChairService} from "../../../services/chair.service";
import {NgForOf} from "@angular/common";
import {FunDialogComponent} from "../../../dialogs/fun-dialog/fun-dialog.component";

@Component({
  selector: 'app-administrator-chairs',
  templateUrl: './administrator-chairs.component.html',
  imports: [
    NgForOf
  ],
  standalone: true,
  styleUrls: ['./administrator-chairs.component.scss']
})
export class AdministratorChairsComponent implements OnInit{
  surveyForm: FormGroup;
  chairs!: Chair[];
  chair!: Chair;

  private page = 1;
  private loading = false;
  private surveysSubscription: Subscription | undefined;
  private chairProperty = ChairProperty;


  constructor(
      private formBuilder: FormBuilder,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private chairService: ChairService,
      private articlesService: ArticlesService,
  ) {
    this.surveyForm = this.formBuilder.group({
      surveyType: ['survey', Validators.required],
      question: ['', Validators.required],
      answerOptions: this.formBuilder.array([
        this.formBuilder.control(''), // Add your initial answer options here
        this.formBuilder.control(''),
      ]),
      correctAnswer: [-1] // Default to -1 (for voting)
    });
  }


  ngOnInit(): void {
    this.chairService.getChair().subscribe(
      (chair: Chair) =>{
        this.chair = chair
      }
    )
    this.chairService.getChairs().subscribe(
        (chairs: Chair[]) => {
          this.chairs = chairs; // Assign the retrieved courses to the courses property
        },
        (error) => {
          this.snackBar.open(error, 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
          console.error('Error getting editors:', error);
        }
    );
  }



  openCreateChairDialog(editor?: Chair) {
    const dialogRef = this.dialog.open(CreateChairDialogComponent, {
      width: '500px',
      data: editor,
    });


    dialogRef.componentInstance.editorCreated.subscribe((createdEditor: Chair) => {
      // Update this.editors if an editor is created successfully
      if (createdEditor) {
        this.chairs.push(createdEditor);

        // Show snackbar with successful message
        this.snackBar.open("Editor created successfully", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const { editor, isUpdated } = result;
      if (isUpdated) {
        // Update the existing editor
        const index = this.chairs.findIndex(chair => chair[ChairProperty.id] === editor._id);
        if (index !== -1) {
          this.chairs[index] = { ...this.chairs[index], ...editor };
          this.snackBar.open("Editor updated successfully", 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        }
      } else {
        // Add the new editor
        this.chairs.push(editor);
        this.snackBar.open("Editor created successfully", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }

      /*if(editor) {
        console.log("HIIII")
        const index = this.chairs.findIndex(chair => chair[ChairProperty.id] === editor[ChairProperty.id]);

        if (index !== -1) {
          // Update the editor at the found index
          this.chairs[index] = {...this.chairs[index], ...editor};
        }

        this.snackBar.open("Editor updated successfully", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }*/
    });
  }

  deleteEditor(editor: any): void{
    if(editor.isKing){
      const dialogRef = this.dialog.open(FunDialogComponent, {
        width: '500px',
        data: {
          title: 'Not happening',
          message1: '',
          image: './assets/rickroll.gif'
        },
      });
    }
    else {
      this.chairService.delete(editor._id).subscribe(
        (result) => {
          const index = this.chairs.findIndex(chair => chair[ChairProperty.id] === editor._id);
          const chair = this.chairs.find(chair => chair[ChairProperty.id] === editor._id);

          if (index !== -1) {
            this.chairs.splice(index, 1); // Remove the editor from the array
          }

          const dialogRef = this.dialog.open(FunDialogComponent, {
            width: '500px',
            data: {
              title: chair?.name + ' has been yoga FIRED',
              message1: '',
              image: './assets/yoga-fire.webp'
            },
          });
          setTimeout(() => {
            dialogRef.close();
          }, 3000); // 3000 milliseconds = 3 seconds

          this.snackBar.open("Korisnik uredno obrisan.", 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        },
        (error) => {
          this.snackBar.open(error, 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        }
      );
    }
  }

}
