@if(data.title){
  <h1 mat-dialog-title style="font-size: 20px; font-family: 'Monrad Semibold'; ">{{ data.title }}</h1>
}
@if(data.message1){
  <mat-dialog-content style="font-size: 18px; color: black">{{ data.message1 }}</mat-dialog-content>
}
@if(data.message2){
  <mat-dialog-content style="font-size: 25px; font-family: 'Monrad Bold'; color: black">{{ data.message2 }}</mat-dialog-content>
}
@if(data.message3){
  <mat-dialog-content>{{ data.message3 }}</mat-dialog-content>
}
@if(data.image){
  <img src="{{data.image}}" alt="Dormeo">
}
